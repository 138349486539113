import React from "react";
import { Link } from "gatsby";
import Section from "../../components/Section/Section";

import "./SolutionCards.scss";

const SolutionCards = ({ sectionHeading, cards }) => {
  return (
    <Section className="SolutionCards">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h3>{sectionHeading}</h3>
          </div>
        </div>
        <div className="row">
          {cards.map((card, i) => {
            const newUrl = new URL(card.link.url);
            return (
              <div className="col-lg-4" key={i}>
                <div className="SolutionCard">
                  <Link to={newUrl.pathname}>
                    <h4>{card.title}</h4>
                    <div className="SolutionCardDescription Body--small">
                      {card.descriptions.length > 65
                        ? `${card.descriptions.substring(0, 65)}...`
                        : card.descriptions}
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default SolutionCards;
